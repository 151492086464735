body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  /* margin-top: 56px; */
}

.screen {
  margin-top: 56px;
  height: calc(100vh - 56px);
  overflow-y: hidden;
  /* overflow-y: scroll; */
}

.loading-spinner {
  width: 100%; height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0; top: 0;
  background: rgba(255, 255, 255, 0.5);
}

.screen-content {
  width: 100%; height: 100%;
  display: flex;
}

.form {
  margin: 2em;
}

.form .cols {
  display: flex;
  flex-direction: row;
  /* column-count: 3;
  column-gap: 1em;
  column-width: 33%; */
}

.form .cols > section {
  column-span: 1;
  margin-right: 2em;
  flex: 1;
}

.form .cols > section:last-child {
  margin-right: 0;
}


.form h5 {
  margin-bottom: .5em;
}

.form section {
  padding-bottom: 2em;
}

.form .field {
  margin-bottom: 1em;
}

.form .col3 {
  /* column-count: 3;
  column-gap: 1em; */
  display: flex;
  flex-direction: row;
}

.form .col3 > div {
  /* width: 10em; */
  margin-right: 2em;
  margin-bottom: 1em;
}

.form .buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /* justify-content: center; */
}

.form .buttons > * {
  margin-right: 1em;
}
.form .buttons > *:last-child {
  margin-right: 0;
}

@media (min-width: 600px) {
  .screen {
    margin-top: 64px;
    height: calc(100vh - 64px);
  }
}
